.ant-table-row {
    &.active {
        background-color: #ffe7ba;
    }

    &.future {
        background-color: #d9f7be;
    }

    &.past {
        background-color: #d9d9d9;
    }
}

.upload-image {
  width: 100%;
}
.upload-image .ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.upload-image .ant-form-item-control-wrapper {
  width: 100%;
}

.ql-editor {
  min-height: 200px;
}
